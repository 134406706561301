import {
  GET_PCI_COMPLIANCE_REQUEST,
  GET_PCI_COMPLIANCE_REQUEST_FAILURE,
  GET_PCI_COMPLIANCE_REQUEST_SUCCESS,
  REMOVE_FREOM_ACTIVE_TAB_LIST_DATA_COMPLIANCE,
  SET_ACTIVE_TAB_LIST_DATA_COMPLIANCE,
} from "./actionTypes";

const initialState = {
  pciComplianceData: undefined,
  isLoading: false,
  error: undefined,
  activeTabsList: [
    {
      name: "PCI",
      link: "/ciso/compliance/pci",
    },
  ],
};

export const complianceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PCI_COMPLIANCE_REQUEST_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        pciComplianceData: data,
        isLoading: false,
      };
    }
    case GET_PCI_COMPLIANCE_REQUEST_FAILURE: {
      const { msg: error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case GET_PCI_COMPLIANCE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case SET_ACTIVE_TAB_LIST_DATA_COMPLIANCE: {
      const { data } = action;
      let temp = [...state.activeTabsList];

      for (let i = 0; i < temp.length; i++) {
        if (temp[i].name == data.name) {
          return {
            ...state,
          };
        }
      }

      temp.push(data);

      return {
        ...state,
        activeTabsList: temp,
      };
    }

    case REMOVE_FREOM_ACTIVE_TAB_LIST_DATA_COMPLIANCE: {
      const { data } = action;
      let temp = [...state.activeTabsList];

      temp = temp.filter((e) => e.name !== data.name);

      return {
        ...state,
        activeTabsList: temp,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
