import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCurrentRole,
  toggleSwitchDashboard,
} from "../../reducers/SwitchRole/actions";
import { makeStyles } from "@material-ui/core/styles";

import "./index.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const roleMap = {
  ciso: "CISO",
  "claribel-super-admin": "Claribel Super Admin",
  operations: "Operations",
  admin: "Admin",
};

export default function SwitchRole() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isOpen: open, currentActiveRole } = useSelector(
    (state) => state.switchRole
  );
  const currentUser = useSelector((state) => state.currentUser.data);
  const activeUserPersona = localStorage.getItem("currentActive");
  const [value, setValue] = useState(
    currentActiveRole ? currentActiveRole : activeUserPersona
  );
  useEffect(() => {
    setValue(activeUserPersona);
  }, [activeUserPersona]);

  // This is commented because of unrelevent

  // useEffect(() => {
  //   if (!currentActiveRole) {
  //     const {
  //       location: { pathname },
  //     } = history;

  //     const activePath = pathname?.split("/")[1];
  //     console.log("aaaaa roleMap", roleMap, roleMap[activePath]);
  //     setTimeout(() => {
  //       dispatch(changeCurrentRole(roleMap[activePath]));
  //     }, 100);
  //     setValue(roleMap[activePath]);
  //   }
  // }, []);

  useEffect(() => {}, [open]);
  useEffect(() => {}, [value]);

  useEffect(() => {}, [currentActiveRole]);

  const toggleModal = () => {
    setValue(activeUserPersona);
    dispatch(toggleSwitchDashboard());
  };
  const handleChange = (event) => {
    const currentValue = event.target.value;

    // alert("Hello");
    console.log(event.target.value);

    // Data Manager

    setValue(currentValue);
  };

  function capitalize(string) {
    if (!string || typeof string !== "string") return undefined;
    if (string === string.toUpperCase()) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  function renderActiveUserRoles() {
    if (!currentUser || !currentUser.roles || currentUser.roles.length === 0) {
      return <h2>No Active User Available</h2>;
    }
    const sortedRoles = [...currentUser.roles].sort((a, b) => {
      let privilegeA = a.privilege.toLowerCase();
      let privilegeB = b.privilege.toLowerCase();
      return privilegeA.localeCompare(privilegeB);
    });
    return sortedRoles.map((currRole) => (
      <FormControlLabel
        key={currRole.privilege}
        value={currRole.privilege}
        control={<Radio />}
        label={currRole.privilege}
      />
    ));
  }

  function redirectToCorrectRoute() {
    toggleModal();
    setTimeout(() => {
      dispatch(changeCurrentRole(value));
    }, 100);

    if (value == "Data Manager") {
      history.push("/configuration-manager/periodic-data-feed/cyber-risk");
      return;
    }
    history.push("/" + value.toLowerCase().replaceAll(" ", "-"));
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          margin: 0,
        },
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleModal}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {"Switch Profile"}
      </DialogTitle>
      <DialogContent className="dialog-container">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="available roles"
            name="roles"
            value={value}
            onChange={handleChange}
          >
            {renderActiveUserRoles()}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button
          className="btn-cancel"
          onClick={toggleModal}
          style={{
            backgroundColor: "#333",
            color: "#fff",
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-done"
          onClick={redirectToCorrectRoute}
          style={{ backgroundColor: "#0090e7", color: "#fff" }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
