import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { GrSend } from "react-icons/gr";
import robo from "../../../assets/robot.png";
import gif from "../../../assets/Android.gif";
import link from "../../../assets/link.png";
import axios from "axios";
// import CloseIcon from "@mui/icons-material/Close";

// Component for user message
const UserMessage = ({ text }) => (
  <div className={styles.user_message}>
    <p>{text}</p>
    <span className={styles.insignia}>Y</span>
  </div>
);

// Component for backend message
const BackendMessage = ({ text, loading }) => (
  <div className={styles.backend_message}>
    <span className={styles.insignia}>
      <img src={robo} alt="" />
    </span>
    {loading ? <div className={styles.loader}>...</div> : <p>{text}</p>}
  </div>
);
// Component to render popular prompts
const PopularPrompts = ({ prompts, handlePromptClick }) => (
  <>
    <div className={styles.popular_prompts}>
      <h4
        style={{
          backgroundColor: "white",
          padding: "5px 10px",
          borderRadius: "5px",
          textAlign: "left",
          color: "black",
        }}
      >
        Popular Prompts
      </h4>
      {prompts.map((prompt, index) => (
        <div
          key={index}
          className={styles.prompt}
          onClick={() => handlePromptClick(prompt)}
        >
          <p>{prompt}</p>
        </div>
      ))}
    </div>
    <div className={styles.new_chat} onClick={() => handlePromptClick("")}>
      &#43; New Chat
    </div>
  </>
);

const Chatbot = ({ setIsMobileOpen }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState("");
  const [screen1, setScreen1] = useState(true);
  const [popularPrompts, setPopularPrompts] = useState([
    "What is Claribel all about?",
    "Tell me the difference between NIST Score and SOC2?",
    "What are impacted areas in my company?",
    "What can I do to improve my NIST Score?",
  ]);
  const [showGif, setShowGif] = useState(true);
  const [showLottie, setShowLottie] = useState(true);
  const [isMaximized, setIsMaximized] = useState(false);
  const [likedPrompts, setLikedPrompts] = useState({});

  const messagesEndRef = useRef(null); // Create a ref for the messages container

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGif(false);
      setShowLottie(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the messages list whenever messages update
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]); // Dependency on messages means this runs every time messages change

  // Handling the sending of messages
  const sendMessage = () => {
    if (input.trim() !== "") {
      const newMessages = [...messages, { text: input, sender: "user" }];
      setMessages(newMessages);
      simulateBackendResponse(input, newMessages);
      setInput("");
    }
  };

  // Simulating a response from a "backend"
  const simulateBackendResponse = (userInput, currentMessages) => {
    const encodedString = encodeURIComponent(userInput);
    // console.log(encodedString);
    setLoading(true);
    const url = `https://ocs.claribel.net/ocs/api/gen-ai/search?userId=naved.mrt%40gmail.com&prompt=${encodedString}`;
    // const params = new URLSearchParams({
    //   userId: "naved.mrt@gmail.com",
    //   prompt: encodedString,
    // });

    // Optionally add a temporary loading message
    const tempMessage = { text: "Loading...", sender: "bot" };
    setMessages([...currentMessages, tempMessage]);

    axios
      .get(url, {
        headers: {
          "X-Tenant-Id": "companya",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        // params: params,
      })
      .then((response) => {
        const botMessage = response.data.response;
        const updatedMessages = [
          ...currentMessages,
          { text: botMessage, sender: "bot" },
        ];
        setLoading(false);
        setMessages(updatedMessages);
      })
      .catch((error) => {
        console.error("API request failed", error);
        setLoading(false);
      });
  };

  // Handling input changes
  const handleChange = (e) => {
    setInput(e.target.value);
  };

  // Rendering messages
  const renderMessages = () => {
    return messages.map((message, index) => {
      if (message.sender === "user") {
        return <UserMessage key={index} text={message.text} />;
      } else {
        // Pass the loading state to the BackendMessage only if it is the last message
        const isLastMessage = index === messages.length - 1;
        return (
          <BackendMessage
            key={index}
            text={message.text}
            loading={isLastMessage && loading}
          />
        );
      }
    });
  };

  const close = () => {
    setIsMobileOpen(false);
  };

  const maximise = () => {
    setIsMaximized(!isMaximized);
  };

  // Handle prompt click
  const handlePromptClick = (prompt) => {
    if (prompt) {
      const newUserMessage = { text: prompt, sender: "user" };
      setMessages([newUserMessage]);
      simulateBackendResponse(prompt, [newUserMessage]);
    }
    setScreen1(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
  };

  const handleLike = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setLikedPrompts((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      <div
        className={`${styles.chatbot_container} ${
          isMaximized ? styles.maximized : ""
        }`}
      >
        <div className={styles.mobile_container}>
          {showGif ? (
            <img src={gif} alt="Loading..." className={styles.loading_gif} />
          ) : (
            <>
              <div className={styles.header}>
                <div>
                  <img
                    src={robo}
                    style={{
                      backgroundColor: "white",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      padding: "8px",
                    }}
                  />
                </div>
                <h3 style={{ fontWeight: "600" }}>Claribot</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{ color: "white", padding: 0 }}
                    onClick={maximise}
                    className={styles.expand}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#ffffff"
                      height="107px"
                      width="107px"
                      version="1.1"
                      id="Layer_1"
                      viewBox="0 0 512 512"
                      stroke="#ffffff"
                      stroke-width="20.991999999999997"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />

                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />

                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M175.445,336.555c-5-5.009-13.099-5.009-18.099,0L25.6,468.301V396.8c0-7.074-5.726-12.8-12.8-12.8 C5.726,384,0,389.726,0,396.8v102.4c0,7.074,5.726,12.8,12.8,12.8h102.4c7.074,0,12.8-5.726,12.8-12.8 c0-7.074-5.726-12.8-12.8-12.8H43.699l131.746-131.746C180.446,349.653,180.446,341.555,175.445,336.555z" />{" "}
                          </g>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M499.2,0H396.8C389.726,0,384,5.726,384,12.8c0,7.074,5.726,12.8,12.8,12.8h71.492L336.555,157.346 c-5.001,5.001-5.001,13.099,0,18.099c5,5.001,13.099,5.001,18.099,0L486.4,43.699V115.2c0,7.074,5.726,12.8,12.8,12.8 c7.074,0,12.8-5.726,12.8-12.8V12.8C512,5.726,506.274,0,499.2,0z" />{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </span>
                  <span style={{ color: "white", padding: 0 }} onClick={close}>
                    {/* &#10060; */}
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge  css-j518jc"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="CloseIcon"
                      aria-label="fontSize large"
                    >
                      <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                    {/* <CloseIcon /> */}
                  </span>
                </div>
              </div>
              {screen1 ? (
                <div className={styles.screen1}>
                  <div className={styles.historyArea}>
                    <h4
                      style={{
                        backgroundColor: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        textAlign: "center",
                        color: "black",
                        width: "fit-content",
                        margin: "auto",
                      }}
                    >
                      History
                    </h4>
                    <div className={styles.promptsArea}>
                      {popularPrompts.map((prompt, index) => (
                        <div
                          key={index}
                          className={styles.prompt}
                          onClick={() => handlePromptClick(prompt)}
                        >
                          <p>{prompt}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={styles.favouriteArea}>
                    <h4
                      style={{
                        backgroundColor: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        textAlign: "center",
                        color: "black",
                        width: "fit-content",
                        margin: "auto",
                      }}
                    >
                      Favourites
                    </h4>
                    <div className={styles.promptsArea}>
                      {popularPrompts.map((prompt, index) => (
                        <div
                          key={index}
                          className={styles.prompt}
                          onClick={() => handlePromptClick(prompt)}
                        >
                          <div
                            onClick={(e) => handleLike(e, index)}
                            style={{
                              position: "absolute",
                              width: "20px",
                              height: "20px",
                              // backgroundColor: "black",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "50%",
                              top: "0px",
                              right: "-3px",
                              // margin: "5px",
                            }}
                          >
                            {likedPrompts[index] ? (
                              <svg
                                viewBox="0 0 44 44"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                role="img"
                                class="iconify iconify--twemoji"
                                preserveAspectRatio="xMidYMid meet"
                                fill="#ffffff"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    fill="#DD2E44"
                                    d="M35.885 11.833c0-5.45-4.418-9.868-9.867-9.868c-3.308 0-6.227 1.633-8.018 4.129c-1.791-2.496-4.71-4.129-8.017-4.129c-5.45 0-9.868 4.417-9.868 9.868c0 .772.098 1.52.266 2.241C1.751 22.587 11.216 31.568 18 34.034c6.783-2.466 16.249-11.447 17.617-19.959c.17-.721.268-1.469.268-2.242z"
                                  ></path>
                                </g>
                              </svg>
                            ) : (
                              <svg
                                width="100%"
                                height="100%"
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke={
                                  likedPrompts[index] ? "#ff0000" : "#ffffff"
                                }
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  <path
                                    d="M8.96173 18.9109L9.42605 18.3219L8.96173 18.9109ZM12 5.50063L11.4596 6.02073C11.601 6.16763 11.7961 6.25063 12 6.25063C12.2039 6.25063 12.399 6.16763 12.5404 6.02073L12 5.50063ZM15.0383 18.9109L15.5026 19.4999L15.0383 18.9109ZM9.42605 18.3219C7.91039 17.1271 6.25307 15.9603 4.93829 14.4798C3.64922 13.0282 2.75 11.3345 2.75 9.1371H1.25C1.25 11.8026 2.3605 13.8361 3.81672 15.4758C5.24723 17.0866 7.07077 18.3752 8.49742 19.4999L9.42605 18.3219ZM2.75 9.1371C2.75 6.98623 3.96537 5.18252 5.62436 4.42419C7.23607 3.68748 9.40166 3.88258 11.4596 6.02073L12.5404 4.98053C10.0985 2.44352 7.26409 2.02539 5.00076 3.05996C2.78471 4.07292 1.25 6.42503 1.25 9.1371H2.75ZM8.49742 19.4999C9.00965 19.9037 9.55954 20.3343 10.1168 20.6599C10.6739 20.9854 11.3096 21.25 12 21.25V19.75C11.6904 19.75 11.3261 19.6293 10.8736 19.3648C10.4213 19.1005 9.95208 18.7366 9.42605 18.3219L8.49742 19.4999ZM15.5026 19.4999C16.9292 18.3752 18.7528 17.0866 20.1833 15.4758C21.6395 13.8361 22.75 11.8026 22.75 9.1371H21.25C21.25 11.3345 20.3508 13.0282 19.0617 14.4798C17.7469 15.9603 16.0896 17.1271 14.574 18.3219L15.5026 19.4999ZM22.75 9.1371C22.75 6.42503 21.2153 4.07292 18.9992 3.05996C16.7359 2.02539 13.9015 2.44352 11.4596 4.98053L12.5404 6.02073C14.5983 3.88258 16.7639 3.68748 18.3756 4.42419C20.0346 5.18252 21.25 6.98623 21.25 9.1371H22.75ZM14.574 18.3219C14.0479 18.7366 13.5787 19.1005 13.1264 19.3648C12.6739 19.6293 12.3096 19.75 12 19.75V21.25C12.6904 21.25 13.3261 20.9854 13.8832 20.6599C14.4405 20.3343 14.9903 19.9037 15.5026 19.4999L14.574 18.3219Z"
                                    fill="#fff"
                                  ></path>
                                </g>
                              </svg>
                            )}
                          </div>

                          <p>{prompt}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <PopularPrompts
                    prompts={popularPrompts}
                    handlePromptClick={handlePromptClick}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.message_container}>
                    <div className={styles.messages}>
                      {renderMessages()}
                      <div ref={messagesEndRef} />{" "}
                    </div>
                  </div>

                  <div className={styles.bottom_container}>
                    <div className={styles.file_upload_container}>
                      <label>
                        <img
                          src={link}
                          alt=""
                          className={styles.attachment_icon}
                        />
                        <input
                          type="file"
                          className={styles.file_input}
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                    <div className={styles.input_container}>
                      <input
                        type="text"
                        className={styles.input}
                        value={input}
                        onChange={handleChange}
                        onKeyPress={(event) =>
                          event.key === "Enter" && sendMessage()
                        }
                      />
                      <span
                        className={styles.sendbtn_container}
                        onClick={sendMessage}
                      >
                        <GrSend fill="white" color="white" />
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Chatbot;
